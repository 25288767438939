import * as React from "react";
import Layout from "../components/layout";

const IndexPage = () => {
  return (
    <Layout pageTitle="rsmb.tv">
      <h2>Hi</h2>
      <p>I'm Robby, sometimes I make maps.</p>
    </Layout>
  );
};

export default IndexPage;
